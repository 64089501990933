import ReactotronManagerBase from "./ReactotronManager_base"

let Reactotron = null // dynamic import

var ReactotronManager = {
  useReactotron() {
    return ReactotronManagerBase.useReactotron()
  },

  loadDynamically() {
    return new Promise(resolve => {
      if (!Reactotron) {
        import(/* webpackChunkName: "reactotron-react-js" */ "reactotron-react-js").then(inst => {
          console.warn(`Dynamic Component 'ReactotronManager' initialized.`)
          Reactotron = inst.default
          // debugger
          resolve()
        })
      } else {
        resolve()
      }
    })
  },

  init() {
    return new Promise(resolve => {
      if (!ReactotronManager.useReactotron()) {
        console.warn("Not using Reactotron.")
        resolve()
        return
      }

      ReactotronManager.loadDynamically().then(() => {
        ReactotronManagerBase.init(Reactotron).then(() => {
          resolve()
        })
      })
    })
  },

  connect() {
    return new Promise(resolve => {
      if (ReactotronManager.useReactotron()) {
        Reactotron.clear()
        console.warn(`ReactotronManager connected.`)
        Reactotron.connect()
      }
      resolve()
    })
  },

  getCreateStoreFunction() {
    return ReactotronManagerBase.getCreateStoreFunction(Reactotron)
  },

  createEnhancer() {
    if (!ReactotronManager.useReactotron()) {
      return null
    }
    return Reactotron.createEnhancer()
  },
}

export default ReactotronManager
