import * as Sentry from "@sentry/react"
import md5 from "md5"

import CONFIG from "../../config"

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("BugReportManager", ...args)

let messagesSentOnceHashes = []
let userContext = {}

const BugReportManager = {
  use: () => {
    const use = !__DEV__ || CONFIG.sentry.reportErrorsOnDev
    return use
  },

  init: ({appName, appVersion = "1.0.0", persistKey = "init"}) => {
    if (!BugReportManager.use()) {
      return
    }
    const release = `${appName}-${appVersion}-${persistKey}`
    const dsn = CONFIG.sentry.dsn
    Sentry.init({
      dsn,
      release,
      // integrations: [new Integrations.BrowserTracing()],
      // tracesSampleRate: 1.0,
    })
    debby("init()", {release, dsn})
  },

  setUserContext: options => {
    if (!BugReportManager.use()) {
      return
    }
    userContext = {...userContext, ...options}
    debby("setUserContext()", options)
    Sentry.setUser(userContext)
  },

  clearUserContext: () => {
    Sentry.setUser({})
  },

  setTagsContext: (tags = {}) => {
    if (!BugReportManager.use()) {
      return
    }
    debby("setTagsContext()", {tags})
    Sentry.setExtra(tags)
  },

  captureMessage: (messageParam, data = {}) => {
    // __DEV__ && console.warn(messageParam, data)
    const dataString = Object.keys(data).length ? `: ${JSON.stringify(data)}` : ""
    const message = messageParam + dataString
    // debby("captureMessage()", {message})
    __DEV__ && console.warn(message)
    if (!BugReportManager.use()) {return} // prettier-ignore
    Sentry.captureMessage(message /*, Sentry.Severity.Warning*/)
  },

  sendMessageOncePerSession: message => {
    if (!BugReportManager.use()) {
      return
    }
    const messageHash = md5(message /* + Sentry.Severity.Warning*/)
    if (messagesSentOnceHashes.indexOf(messageHash) != -1) {
      return
    }
    messagesSentOnceHashes.push(messageHash)
    debby("sendMessageOncePerSession()", {message})

    setTimeout(() => BugReportManager.captureMessage(message /*, Sentry.Severity.Warning*/), 3000)
  },

  captureException: (error, data = {}) => {
    __DEV__ && console.error("BugReportManager.captureException(): " + error)
    if (!BugReportManager.use()) {
      return
    }
    if (!error) {
      BugReportManager.captureMessage("First function param 'error' not set.")
      return
    }
    debby("captureException()", {error, data})
    Sentry.captureException(new Error(error), data)
  },

  addBreadcrumb: (category, messageRaw, data = null) => {
    if (!BugReportManager.use() || !category || !messageRaw) {
      return
    }
    let message = messageRaw
    if (!!data) {
      message += ` ${JSON.stringify(data)}`
    }
    debby("addBreadcrumb()", {category, messageRaw, data, message})
    Sentry.addBreadcrumb({category, message /*, level: Sentry.Severity.Info*/})
  },
}

export default BugReportManager
