// import queryString from "query-string"
import {createSelector} from "reselect"
import {getObjectDeep as god, getDeviceLocaleUsable} from "./helpers"
import {getIntlLocale} from "./intl" // eslint-disable-line
import CONFIG from "../../config"

export const getSitemapData = state => state.api.sitemap
const getStateApiPostData = state => state.api.postData

export const getLocationPathname = (_, props) => props.location.pathname
export const getPropsLocation = (_, props) => props.location
// export const getQueryProps = (_, props) => queryString.parse(props.location.search)
const getLocation = (_, props) => props.location

export const getPostData = createSelector(
  //
  [getLocation, getStateApiPostData],
  (location, stateApiPostData) => {
    const postData = god(stateApiPostData, [location.pathname, "data"])
    return postData
  },
)

export const getSitemapDataAndLangForPathname = (sitemap, pathname, params = {}) => {
  const debug = god(params, "debug", false) // eslint-disable-line
  if (!sitemap || !pathname) {
    return null
  }
  const pathnameSplit = pathname.split("/").filter(p => !!p)

  let langFound = null
  let entryFull = null
  let entryExact = null

  for (const entry of sitemap) {
    if (!!entry && !!entry.langs && !langFound) {
      const {langs} = entry

      for (const lang of Object.keys(langs)) {
        if (!langFound) {
          const {path} = langs[lang]

          const pathClean = !!path ? path.split("/").filter((p) => !!p).join("/") : "" //  prettier-ignore

          if (pathClean == pathnameSplit.join("/")) {
            // 1:1 match
            langFound = lang
            entryFull = entry
            entryExact = entryFull.langs[langFound]
          } else {
            // Also handle parameterized routes, e.g. /artworks/123
            const pathCleanSplit = pathClean.split("/").filter(p => !!p)

            if (pathCleanSplit.length == pathnameSplit.length) {
              const ppMatching = pathnameSplit.filter((pathnamePart, i) => {
                const pathCleanPart = pathCleanSplit[i]
                const isParam = pathCleanSplit[i].charAt(0) == ":"
                return isParam ? true : pathCleanPart.toLowerCase() == pathnamePart.toLowerCase()
              })

              if (ppMatching.length == pathnameSplit.length) {
                langFound = lang
                entryFull = {
                  ...entry,
                  langs: {
                    [langFound]: {
                      path: pathname,
                    },
                  },
                }
                entryExact = entryFull.langs[langFound]
              }
            }
          }
        }
      }
    }
  }

  if (debug) { debugger } // prettier-ignore

  return {lang: langFound, entryFull, ...entryExact} // {lang, entryFull {pageTemplate, langs}, langs, pageTemplate}
}
export const getIntlLocaleLocation = createSelector([getLocation, getSitemapData], (location, sitemap) => {
  const itemFound = getSitemapDataAndLangForPathname(sitemap, location.pathname)
  return god(itemFound, "lang", "en")
})

export const getCurrentPageSitemapDataEntry = createSelector([getLocation, getSitemapData], (location, sitemapData) => {
  return getSitemapDataAndLangForPathname(sitemapData, location.pathname, {debug: false})
})

export const getPageTemplate = createSelector([getCurrentPageSitemapDataEntry], currentPageSitemapDataEntry => {
  return god(currentPageSitemapDataEntry, "entryFull.pageTemplate")
})

export const getHomePathLocalized = createSelector([getSitemapData, getDeviceLocaleUsable], (sitemapData, deviceLocaleUsable) => {
  if (!sitemapData) {
    return undefined
  }
  const entry = sitemapData.find(e => e.pageTemplate == "home")
  if (!entry || !entry.langs) {
    debugger
    return "/"
  }
  for (const lang of Object.keys(entry.langs)) {
    if (lang == deviceLocaleUsable) {
      return entry.langs[lang].path
    }
  }
  debugger
  return entry.langs[Object.keys(entry.langs)[0]].path
})

export const getCurrentLocationPathsInLangs = createSelector([getSitemapData, getLocation], (sitemapData, location) => {
  if (!sitemapData || !location || !location.pathname) {
    return undefined
  }
  const langData = getSitemapDataAndLangForPathname(sitemapData, location.pathname)
  if (!langData || !langData.entryFull || !langData.entryFull.langs) {
    return null
  }
  const linksInLangs = Object.fromEntries(
    CONFIG.intl.langs.map(lang => {
      const path = god(langData.entryFull.langs, `${lang}.path`)
      return [lang, path]
    }),
  )
  return linksInLangs
})

export const getLinkToHome = createSelector([getSitemapData, getIntlLocale], (sitemapData, intlLocale) => {
  if (!sitemapData) return undefined
  const entry = sitemapData.find(e => e.pageTemplate == "home")
  if (!entry) return "/"
  return god(entry, `langs.${intlLocale}.path`, "/")
})
export const getLinkToImpressum = createSelector([getSitemapData, getIntlLocale], (sitemapData, intlLocale) => {
  if (!sitemapData) return undefined
  const entry = sitemapData.find(e => e.pageTemplate == "impressum")
  if (!entry) return "/"
  return god(entry, `langs.${intlLocale}.path`, "/")
})
export const getLinkToDatenschutz = createSelector([getSitemapData, getIntlLocale], (sitemapData, intlLocale) => {
  if (!sitemapData) return undefined
  const entry = sitemapData.find(e => e.pageTemplate == "datenschutz")
  if (!entry) return "/"
  return god(entry, `langs.${intlLocale}.path`, "/")
})
