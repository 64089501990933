import {updateIntl} from "react-intl-redux"
import {getLangDynamic} from "../../lang/langsDynamic"

import * as actionsConsts from "../../data/actionsConsts"

import {debbify, getObjectDeep as god, getLocaleSafe} from "../../data/selectors/helpers" // eslint-disable-line
const debby = (...args) => debbify("ADevice", ...args)

export const setStageDimensions = (width, height) => dispatch => {
  dispatch({type: actionsConsts.SET_STAGE_DIMENSIONS, width, height})
}

export const setTabVisible = visible => dispatch => {
  debby("setTabVisible()", {visible})
  dispatch({type: actionsConsts.SET_TAB_VISIBLE, visible})
}

let lastLocaleSafe = undefined
export const setIntlLocale = locale => dispatch => {
  const localeSafe = getLocaleSafe(locale)
  if (localeSafe == lastLocaleSafe) {
    return
  }
  lastLocaleSafe = localeSafe

  debby("setIntlLocale()", {locale, localeSafe})
  getLangDynamic(localeSafe).then(langData => {
    dispatch(updateIntl({locale: localeSafe, messages: langData.messages}))
  })
}
