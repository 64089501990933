import React, {Component} from "react"
import PropTypes from "prop-types"
import {createBrowserHistory} from "history"
import isEqual from "lodash/isEqual"
import {ConnectedRouter} from "connected-react-router"
import {Route} from "react-router-dom"
import {Provider} from "react-intl-redux"
import {PersistGate} from "redux-persist/integration/react"

import * as selectors from "./data/selectors"

import {createAndPersistStore} from "./data/store"

// import AppWithState from "./view/AppWithState_Basic"
import AppWithState from "./view/AppWithState"
// import CAppDebugOverlay from "./view/components/CAppDebugOverlay"

import CONFIG from "./config" // eslint-disable-line

const {basename} = CONFIG.routing
let usedHistory = createBrowserHistory({basename})

let storeRef = null
let persistorRef = null

const DEBUG = true && __DEV__ // eslint-disable-line

import {debbify} from "./data/selectors/helpers"
const debby = (...args) => debbify("App", ...args)

/*
import logo from "./logo.svg"
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and <b>save to reload</b>.
        </p>
        <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
          Learn React
        </a>
      </header>
      <CAppDebugOverlay />
    </div>
  )
}
*/

class App extends Component {
  static propTypes = {
    usePersistGate: PropTypes.bool,
  }
  static defaultProps = {
    usePersistGate: true,
  }

  constructor(props) {
    super(props)
    this.state = {
      drawProvider: false,
      uidInit: null,
    }
  }

  componentWillMount = async () => {
    debby("componentWillMount()")
  }

  componentDidMount = async () => {
    let localeDefault = selectors.getDeviceLocaleUsable()
    // localeDefault = "de"

    const urlParams = selectors.getAllUrlParams(window.location.href)

    debby("componentDidMount()", {localeDefault, urlParams})

    // let phonePrefix = undefined // see intialState for RDevice
    // if (!!urlParams && Object.keys(urlParams).length && urlParams.hasOwnProperty("pre")) {
    //   phonePrefix = urlParams.pre
    //   debugger
    // }

    // will purge in case storePersistKey changed in config.js
    const {store, persistor} = await createAndPersistStore({locale: localeDefault, history: usedHistory})

    // store.dispatch(actions.initFirebase()) // AFirebase.js
    // store.dispatch(actions.setAnalyticsUserProperty("language", localeDefault)) // AAnalytics.js

    debby("componentDidMount()", {urlParams, localeDefault})

    storeRef = store
    persistorRef = persistor
    this.setState({drawProvider: true})
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  renderRoute = ({location}) => <AppWithState location={location} />

  render = () => {
    const {drawProvider} = this.state
    const {usePersistGate} = this.props
    debby("render()", {drawProvider})
    if (!drawProvider) {
      return <div style={{height: 10000}} /> // enables scroll restauration on reload
    }
    const renderRouter = () => (
      <ConnectedRouter history={usedHistory}>
        <Route render={this.renderRoute} />
      </ConnectedRouter>
    )

    if (usePersistGate) {
      return (
        <Provider store={storeRef}>
          <PersistGate loading={<PersistLoadingIndicator />} persistor={persistorRef}>
            {renderRouter()}
          </PersistGate>
        </Provider>
      )
    }
    return <Provider store={storeRef}>{renderRouter()}</Provider>
  }
}

class PersistLoadingIndicator extends Component {
  componentWillUnmount = () => {
    // console.log("PersistLoadingIndicator.componentWillUnmount()")
  }
  render = () => {
    // console.log("PersistLoadingIndicator.render()")
    return null
  }
}

export default App
