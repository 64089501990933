import SHome from "../view/screens/SHome"
import SImpressum from "../view/screens/SImpressum"
import SDatenschutz from "../view/screens/SDatenschutz"

export const getScreenComponentForPageTemplate = pageTemplate => {
  switch (pageTemplate) {
    case "home": {
      return SHome
    }
    case "impressum": {
      return SImpressum
    }
    case "datenschutz": {
      return SDatenschutz
    }
    default: {
      // debugger
      if (__DEV__) {
        throw new Error(`No component found for pageTemplate '${pageTemplate}'.`)
      }
      return null
    }
  }
}
