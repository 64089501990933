import {createSelector} from "reselect"
import {getPostData} from "./routes"
import {getObjectDeep as god} from "./helpers"

export const getHeroImageWp = createSelector([getPostData], postData => god(postData, "hero.image"))

export const getSocialMediaUrls = createSelector([getPostData], postData => {
  if (!postData) return null
  const socialMediaUrls = god(postData, "contact.socialMedia")
  return socialMediaUrls
})

export const getPostDataContent = createSelector([getPostData], postData => god(postData, "content"))

export const getContact = createSelector([getPostData], postData => god(postData, "contact"))
