import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"

import Slider from "react-slick"
import "../../styles/react-slick/nb-react-slick.scss"

import styled, {ThemeProvider, css} from "styled-components"
import CONFIG from "../../config"

import {MaxScreenWidthContainer} from "../../view/components/styled"
import CReveal from "../../view/components/CReveal"

import * as selectors from "../../data/selectors"
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CHomeSlider", ...args) // eslint-disable-line

import CaretPrev from "../../img/caret-prev.png"
import CaretNext from "../../img/caret-next.png"

const DEBUG = false && __DEV__

class CHomeSlider extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    imageWp: PropTypes.object,
    socialMediaUrls: PropTypes.object,
    pathsInLangs: PropTypes.object,
    imageUrls: PropTypes.array,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  // componentDidMount = () => {
  //   debby("componentDidMount()")
  //   this.debugInterval = setInterval(() => {
  //     console.log(`this.sliderObj exists? ${!!this.sliderObj}`)
  //   }, 2000)
  // }

  // componentWillUnmount = () => {
  //   clearInterval(this.debugInterval)
  // }

  render = () => {
    const {intl} = this.props // eslint-disable-line
    const {imageWp, socialMediaUrls, pathsInLangs, imageUrls} = this.props // eslint-disable-line
    const imageWpSources = !imageWp ? null : selectors.getSrcAndSrcSetByWordpressImage(imageWp)
    const d = {debug: DEBUG.toString()}

    if (!imageUrls) return null
    debby("render()")
    const sliderSettings = {
      centerMode: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      pauseOnHover: false,
      prevArrow: <ArrowLeft />,
      nextArrow: <ArrowRight />,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 1000,
      responsive: [
        {
          //
          // breakpoint: CONFIG.layout.breakpoints.lg.min,
          breakpoint: CONFIG.layout.breakpoints.md.min,
          settings: {
            slidesToShow: 1,
            centerPadding: "20%",
          },
        },
      ],
    }

    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <CReveal>
            <MaxScreenWidthContainer {...d}>
              <SwiperContainer {...d}>
                <Slider
                  //
                  // ref={obj => (this.sliderObj = obj)}
                  {...sliderSettings}
                >
                  {imageUrls.map((url, i) => (
                    <SwiperItemContainer
                      //
                      {...d}
                      key={`image_${i}`}
                      aspectRatio={920 / 1340}
                    >
                      <SwiperItemContent {...d}>
                        <SwiperImage src={url} />
                      </SwiperItemContent>
                    </SwiperItemContainer>
                  ))}
                </Slider>
              </SwiperContainer>
            </MaxScreenWidthContainer>
          </CReveal>
          {false && <pre style={{fontSize: 10}}>{JSON.stringify(imageWpSources, null, 2)}</pre>}
        </Container>
      </ThemeProvider>
    )
  }
}

const ArrowLeft = props => (
  <ArrowLeftContainer onClick={props.onClick}>
    <CaretImg src={CaretPrev} />
  </ArrowLeftContainer>
)
ArrowLeft.propTypes = {
  onClick: PropTypes.func,
}
const ArrowRight = props => (
  <ArrowRightContainer onClick={props.onClick}>
    <CaretImg src={CaretNext} />
  </ArrowRightContainer>
)
ArrowRight.propTypes = {
  onClick: PropTypes.func,
}

const Container = styled.div`
  position: relative;
  width: 100%;
  // border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  ${props => selectors.getDebugOverlayCss(props, "CHomeSlider.Container", "rgba(255,255,0,0.35)")}
  padding: 90px 0px;
`

const SwiperContainer = styled.div`
  border: ${props => (props.debug == "true" ? 3 : 0)}px dotted yellow;
  position: relative;
  width: 100%;
`

const SwiperItemContainer = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px dashed blue;
  margin: 0 auto; // centeredSlides
  ${props =>
    props.debug == "true" &&
    css`
      background-color: red;
    `}

  ${props =>
    props.hasOwnProperty("aspectRatio") &&
    css`
      padding-top: ${(1 / props.aspectRatio) * 100}%;
    `}
`

const SwiperItemContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SwiperImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ArrowBaseStyle = css`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1000;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${props => css`
    ${props.theme.media.xl} {
      width: ${props.theme.vars.padding.horizontal.content.xl}px;
    }
  `}
  ${props => css`
    ${props.theme.media.lg} {
      width: ${props.theme.vars.padding.horizontal.content.lg}px;
    }
  `}
  ${props => css`
    ${props.theme.media.smdown} {
      display: none;
    }
  `}
`

const ArrowLeftContainer = styled.button`
  ${ArrowBaseStyle}
  left: 0;
  // background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  background-color: ${props => (props.debug == "true" ? "blue" : "black")};
`
const ArrowRightContainer = styled.button`
  ${ArrowBaseStyle}
  right: 0;
  // background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
  background-color: ${props => (props.debug == "true" ? "blue" : "black")};
`

const CaretImg = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`

// eslint-disable-next-line
const mapStateToProps = (state, props) => ({
  // locationPathname: selectors.getLocationPathname(state, props),
  // homePathLocalized: selectors.getHomePathLocalized(state, props), // can be undefined
})
export default injectIntl(connect(mapStateToProps)(CHomeSlider))
