const SERVER_ROOT = "https://andreaslugmayr.at"
const SERVER_BASENAME = "" // also change this in package.json > homepage

const PERSIST_STORE = true
const PERSIST_KEY = "Release"
const USE_REDUX_LOGGER = false
const VERSION_SHOWN = "1.0.1" // same as in package.json

const CONFIG = {
  versionShown: VERSION_SHOWN, // shown on screen bottom
  routing: {
    basename: SERVER_BASENAME,
  },
  app: {
    documentTitle: "ZoomSport",
    isReleaseVersion: !SERVER_BASENAME,
    isStagingVersion: !!SERVER_BASENAME,
  },
  intl: {
    baseLang: "de",
    langs: ["de" /*, "en"*/], // same as $langsAvailable in NBSitemap::update()
  },
  server: {
    root: SERVER_ROOT,
    folderReact: `/${SERVER_BASENAME}`, // must match package.json
  },
  redux: {
    storePersistKey: PERSIST_KEY + `-${VERSION_SHOWN}` + (PERSIST_STORE ? "" : "___" + new Date().getTime()), // change key to flush store
    useReactotron: true, // && __DEV__
    useLogger: USE_REDUX_LOGGER && __DEV__,
    reactotronProps: {
      name: "andreaslugmayr.at",
    },
    loggerProps: {
      diff: true,
      collapsed: true,
      timestamp: false,
    },
    reducers: {
      whitelist: ["api"],
    },
  },
  sentry: {
    dsn: "https://6676be3e19b14056bc89665a13ad3d43@o4505011910737920.ingest.sentry.io/4505046005252096",
    reportErrorsOnDev: true,
  },
  /*
    firebase: {
      useAnalyticsInDev: false,
      useAnalyticsForAdmins: false,
      config: {
        apiKey: "AIzaSyA_GjMa4sczIeQweroV_7mEbqj3Kv1p4YE",
        authDomain: "zoomsport-web.firebaseapp.com",
        projectId: "zoomsport-web",
        storageBucket: "zoomsport-web.appspot.com",
        messagingSenderId: "118508279305",
        appId: "1:118508279305:web:740f2eaa680b01ec61cad8",
        measurementId: "G-YL2VCNRPYX",
        databaseURL: "https://zoomsport-web-default-rtdb.firebaseio.com",
      },
    },
    */
  layout: {
    breakpoints: {
      // same as in _variables.scss
      xs: {max: 576}, // Smartphone
      sm: {min: 576, max: 768}, // Big-Ass Smartphone
      md: {min: 768, max: 992}, // iPad
      lg: {min: 992, max: 1200}, // Desktop Small
      xl: {min: 1200}, // Desktop Large
    },
    colors: {
      bodyBg: "#060318",
      // https://chir.ag/projects/name-that-color
      // General
      // jaguar: "#060318", // header backgroundd
      heroBg: "#171102",
      // heroBg: "red",
      halfbaked: "#7FC9CE",
      martinique: "#392C55",
      tacha: "#BDD25B",
      terracotta: "#E0735F",
      alabaster: "#fafafa",
      // Team Members
      tm1bg: "#f26b58",
      tm1fg: "#5fcacf",
      tm2bg: "#b5d342",
      tm2fg: "#f26b58",
      tm3bg: "#3c2a58",
      tm3fg: "#b5d342",
      tm4bg: "#5fcacf",
      tm4fg: "#3c2a58",
      fixedProjectBg: "#f7bc25",
      fixedProjectBgNew: "#FFFFFF",
      fixedProjectFg: "#000000",
    },
  },
}

module.exports = CONFIG
