import * as actionsConsts from "../actionsConsts"
import {getObjectDeep as god} from "../selectors/helpers"

export const initialStateFirebase = {
  initialized: false,
  analyticsReady: false,
  analyticsEnabled: false,
  analyticsDisabledReason: null,
}

export function firebase(state = initialStateFirebase, action) {
  switch (action.type) {
    case "persist/REHYDRATE": {
      const firebase = god(action, "payload.firebase", {})
      return {
        //
        ...state,
        ...firebase,
        initialized: false,
        analyticsReady: false,
        analyticsEnabled: false,
        analyticsDisabledReason: null,
      }
    }
    case actionsConsts.SET_FIREBASE_INITIALIZED: {
      return {...state, initialized: true}
    }
    // case actionsConsts.SET_FIREBASE_USER_DATA:
    //   return {...state, userUid: action.uid, userPhoneNumber: action.phoneNumber}
    // case actionsConsts.SET_USE_LOCAL_SERVER_FOR_FUNCTIONS: {
    //   const {useLocalServer, localServerRoot} = action
    //   return {...state, functionsInitialized: true, functionsUseLocalServer: useLocalServer, functionsLocalServerRoot: localServerRoot}
    // }
    // case actionsConsts.RECEIVE_USER_DATA: {
    //   const {data} = action
    //   return {...state, userData: data}
    // }
    // case actionsConsts.SET_USER_IS_ADMIN: {
    //   return {...state, userIsAdmin: action.isAdmin}
    // }
    // case actionsConsts.RECEIVE_USER_PROFILE: {
    //   const {uid, data} = action
    //   return {
    //     ...state,
    //     userProfilesByUid: {
    //       ...state.userProfilesByUid,
    //       [uid]: {
    //         //
    //         updated: new Date().getTime(),
    //         data,
    //       },
    //     },
    //   }
    // }
    // case actionsConsts.SET_FIREBASE_GLOBALS: {
    //   const {globals} = action
    //   return {
    //     ...state,
    //     globals,
    //   }
    // }
    case actionsConsts.SET_ANALYTICS_READY_ENABLED: {
      const {enabled, reason} = action
      return {
        ...state,
        analyticsReady: true,
        analyticsEnabled: enabled,
        analyticsDisabledReason: reason,
      }
    }
    default:
      return state
  }
}
