import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line
import * as selectors from "../../../data/selectors"

export const MaxScreenWidthContainer = styled.div`
  position: relative;
  max-width: ${props => props.theme.vars.maxWidth.container}px;
  margin: 0 auto;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
`

export const PaddedContentContainer = styled.div`
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  position: relative;
  margin: 0;
  padding: 0;
  ${props => selectors.getPaddingLeftRightByThemeAndVariant(props.theme, "content")}
`

export const QueryLarge = styled.div`
  display: block;
  ${props => css`
    ${props.theme.media.mddown} {
      display: none;
    }
  `}
`

export const QueryMedium = styled.div`
  display: none;
  ${props => css`
    ${props.theme.media.md} {
      display: block;
    }
  `}
`

export const QuerySmall = styled.div`
  display: none;
  ${props => css`
    ${props.theme.media.smdown} {
      display: block;
    }
  `}
`
