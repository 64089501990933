import * as actionsConsts from "../actionsConsts"

export const initialState = {
  postData: {}, // by path
  sitemap: null,
}

export function api(state = initialState, action) {
  switch (action.type) {
    // case actionsConsts.RECEIVE_GEO_INFOS: {
    //   const {geoInfos, geoInfosValidForLogin, geoInfosValidForReservation} = action
    //   return {
    //     //
    //     ...state,
    //     geoInfos,
    //     geoInfosValidForLogin,
    //     geoInfosValidForReservation,
    //   }
    // }
    case actionsConsts.RECEIVE_POST_DATA: {
      const {path, pageTemplate, data} = action
      return {
        //
        ...state,
        postData: {
          ...state.postData,
          [path]: {pageTemplate, data},
        },
      }
    }
    case actionsConsts.RECEIVE_SITEMAP: {
      const data = !!action.data && !!action.data.length ? action.data : []
      return {...state, sitemap: data}
    }
    default:
      return state
  }
}
