import React, {Component} from "react"
import PropTypes from "prop-types"
import {injectIntl} from "react-intl"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import isEqual from "lodash/isEqual"
import {replace} from "connected-react-router" // eslint-disable-line
import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import CHero from "../../components/CHero"
import CHomeMain from "../../components/CHomeMain"
import CHomeSlider from "../../components/CHomeSlider"
import CHomeClients from "../../components/CHomeClients"
import CContact from "../../components/CContact"

import * as selectors from "../../../data/selectors"
import {debbify, getObjectDeep as god} from "../../../data/selectors/helpers"
const debby = (...args) => debbify("SHome", ...args)

const DEBUG = true && __DEV__

class SHome extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    locationPathname: PropTypes.string.isRequired,
    heroImageWp: PropTypes.object,
    socialMediaUrls: PropTypes.object,
    pathsInLangs: PropTypes.object,
    mainTextHeader: PropTypes.string,
    mainTextLeft: PropTypes.string,
    mainTextRight: PropTypes.string,
    citationImageSquares: PropTypes.array,
    sliderImagesUrls: PropTypes.array,
    clients: PropTypes.object,
    contact: PropTypes.object,
  }

  componentDidMount = () => {
    const {locationPathname} = this.props
    debby("componentDidMount()", {locationPathname})
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {heroImageWp, socialMediaUrls, pathsInLangs, mainTextHeader, mainTextLeft, mainTextRight, citationImageSquares, sliderImagesUrls, clients, contact} = this.props
    const d = {debug: DEBUG.toString()} // eslint-disable-line
    debby("render()", {heroImageWp})
    return (
      <ThemeProvider theme={{}}>
        <CHero
          //
          pathsInLangs={pathsInLangs}
          imageWp={heroImageWp}
          socialMediaUrls={socialMediaUrls}
        />
        <CHomeMain
          //
          textHeader={mainTextHeader}
          textLeft={mainTextLeft}
          textRight={mainTextRight}
          citationSquares={citationImageSquares}
        />
        <CHomeSlider
          //
          imageUrls={sliderImagesUrls}
        />
        {!!clients && (
          <CHomeClients
            //
            header={god(clients, "header")}
            entries={god(clients, "entries")}
          />
        )}
        {!!contact && (
          <CContact
            //
            {...contact}
            socialMediaUrls={socialMediaUrls}
          />
        )}
      </ThemeProvider>
    )
  }
}

const getMainTextHeader = createSelector([selectors.getPostData], postData => god(postData, "mainTextHeader"))
const getMainTextLeft = createSelector([selectors.getPostData], postData => god(postData, "mainTextColumns.left"))
const getMainTextRight = createSelector([selectors.getPostData], postData => god(postData, "mainTextColumns.right"))

const getSliderImagesUrls = createSelector([selectors.getPostData], postData => {
  if (!postData) return null
  const sliderImagesUrls = god(postData, "sliderImages", []).map(i => i.url)
  return !!sliderImagesUrls.length ? sliderImagesUrls : null
})

const getCitationImageSquares = createSelector([selectors.getPostData], postData =>
  god(postData, "citationImageSquares", []).map(entry => {
    const {type, showQuotationMark, text, image} = entry
    return {
      //
      type,
      ...(type == "text" ? {text} : {}),
      ...(type == "text" ? {showQuotationMark} : {}),
      ...(type == "image" ? {imageUrl: god(image, "sizes.1000c.url")} : {}),
    }
  }),
)
const getClients = createSelector([selectors.getPostData], postData => god(postData, "clients"))

const mapStateToProps = (state, props) => ({
  locationPathname: selectors.getLocationPathname(state, props),
  // Hero (NBCFGlobals)
  pathsInLangs: selectors.getCurrentLocationPathsInLangs(state, props),
  heroImageWp: selectors.getHeroImageWp(state, props),
  socialMediaUrls: selectors.getSocialMediaUrls(state, props),
  // Main Text
  mainTextHeader: getMainTextHeader(state, props),
  mainTextLeft: getMainTextLeft(state, props),
  mainTextRight: getMainTextRight(state, props),
  citationImageSquares: getCitationImageSquares(state, props),
  // Slider
  sliderImagesUrls: getSliderImagesUrls(state, props),
  // Clients
  clients: getClients(state, props),
  // Contact (NBCFGlobals)
  contact: selectors.getContact(state, props),
})
export default injectIntl(connect(mapStateToProps)(SHome))
