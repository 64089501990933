import {applyMiddleware, combineReducers, createStore, compose} from "redux"
import thunkMiddleware from "redux-thunk"
import {persistStore, persistReducer} from "redux-persist"
import {intlReducer} from "react-intl-redux"
import {connectRouter, routerMiddleware} from "connected-react-router"
import storage from "redux-persist/lib/storage"

import {langs, getLangDynamic} from "../lang/langsDynamic"

import ReactotronManager from "./utils/ReactotronManager"
import ReduxLoggerManager from "./utils/ReduxLoggerManager"
import HelperPlatform from "./utils/HelperPlatform"

import {initialStateDevice} from "./reducers/RDevice"
import {initialState as initialStateApi} from "./reducers/RAPI"

import * as reducers from "./reducers"

import CONFIG from "../config"

import {debbify} from "../data/selectors/helpers"
const debby = (...args) => debbify("store", ...args)

let globalStore = null
let persistor = null // eslint-disable-line

export const saveStore = store => {
  globalStore = store
}
export const getStore = () => {
  return globalStore
}

export const createAndPersistStore = async (options = {}) => {
  const locale = options.locale || "de"
  const history = options.history

  const reducersInit = {
    ...reducers, // all from data/reducers/index.js
    router: connectRouter(history),
    intl: intlReducer,
  }

  let middleware = [thunkMiddleware]

  const {storePersistKey} = CONFIG.redux
  const lastPersistKey = await storage.getItem(`andreaslugmayr_lastPersistKey`) // eslint-disable-line
  let purge = lastPersistKey != storePersistKey

  debby("createAndPersistStore()", {purge, storePersistKey, lastPersistKey})

  if (purge) {
    await localStorage.clear()
  }
  await storage.setItem(`andreaslugmayr_lastPersistKey`, storePersistKey)

  const createLogger = await ReduxLoggerManager.getCreateLoggerFunction()
  if (!!createLogger) {
    middleware.push(
      createLogger({
        predicate: (getState, action) => true, // eslint-disable-line
        ...CONFIG.redux.loggerProps,
      }),
    )
  }

  if (!!history) {
    middleware.push(routerMiddleware(history))
  }

  let deviceLocaleUsable = locale
  if (langs.indexOf(deviceLocaleUsable) == -1) {
    deviceLocaleUsable = "en"
  }

  if (!!window.langPath) {
    deviceLocaleUsable = window.langPath // from index.php
    debby("Language from index.php injection: " + window.langPath)
  }

  HelperPlatform.setDocumentLang(deviceLocaleUsable)
  const langData = await getLangDynamic(deviceLocaleUsable)
  const reducer = combineReducers(reducersInit)

  let initialState = {
    intl: {
      defaultLocale: "en",
      locale: deviceLocaleUsable,
      messages: langData.messages,
    },
    device: {
      ...initialStateDevice,
      stageDimensions: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    },
    api: initialStateApi,
  }

  if (!!window.postDataPath && !!window.pathDataEncoded) {
    const {postDataPath, pathDataEncoded} = window
    const {pageTemplate} = pathDataEncoded
    initialState.api = {
      ...initialState.api,
      postData: {
        ["/" + pathDataEncoded.slug]: {
          pageTemplate,
          data: pathDataEncoded,
        },
      },
    }
    debby("createAndPersistStore(): Path data injected successfully.", {postDataPath, pathDataEncoded, initialStateNew: initialState}, false)
  } else {
    if (!__DEV__) {
      throw new Error("Missing injected path data.")
    }
  }

  if (!!window.sitemapDataEncoded && !!window.sitemapDataEncoded.length) {
    const {sitemapDataEncoded} = window
    initialState.api = {
      ...initialState.api,
      sitemap: sitemapDataEncoded,
    }
    debby("createAndPersistStore(): Sitemap data injected successfully.", {sitemapDataEncoded, initialStateNew: initialState}, false)
  } else {
    if (!__DEV__) {
      throw new Error("Missing injected sitemap data.")
    }
  }

  // debby("createAndPersistStore()", {initialState})

  const persistConfig = {
    key: storePersistKey,
    storage,
    whitelist: CONFIG.redux.reducers.whitelist,
  }

  const persistedReducer = persistReducer(persistConfig, reducer, initialState)

  let store = null
  await ReactotronManager.init()
  if (ReactotronManager.useReactotron()) {
    store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middleware), ReactotronManager.createEnhancer()))
  } else {
    store = createStore(persistedReducer, initialState, compose(applyMiddleware(...middleware)))
  }

  saveStore(store)

  persistor = persistStore(store, {manualPersist: false})
  await ReactotronManager.connect()
  return {store, persistor}
}
