/* istanbul ignore file */

import CONFIG from "../../config"

var ReduxLoggerManager = {
  getCreateLoggerFunction: () => {
    return new Promise(resolve => {
      if (/*__DEV__ && */ CONFIG.redux.useLogger) {
        import(/* webpackChunkName: "redux-logger" */ "redux-logger").then(inst => {
          resolve(inst.createLogger)
        })
      } else {
        resolve(null)
      }
    })
  },
}

export default ReduxLoggerManager
