import {addLocaleData} from "react-intl"
// import messagesEn from "./messages_en.json"
// import messagesCa from "./messages_ca.json"
// import messagesCa from "./messages_ca.json"
import CONFIG from "../config"

const dummyPluralRuleFunction = () => true

export const baseLang = CONFIG.intl.baseLang
export const langs = CONFIG.intl.langs

const importMessages = lang => {
  return new Promise(resolve => {
    const proceedAfterImport = imp => resolve(imp.default)
    switch (lang) {
      case "de":
        import(/* webpackChunkName: "messages-de" */ "./messages_de.json").then(proceedAfterImport)
        break

      case "en":
        import(/* webpackChunkName: "messages-en" */ "./messages_en.json").then(proceedAfterImport)
        break
    }
  })
}

export const getLangDynamic = lang => {
  return new Promise(resolve => {
    importMessages(lang).then(messages => {
      const data = {
        locale: lang,
        pluralRuleFunction: dummyPluralRuleFunction,
        messages: messages,
      }
      addLocaleData(data)
      resolve({
        messages,
        data,
      })
    })
  })
}
