import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"
import {Link} from "react-router-dom"

import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import {MaxScreenWidthContainer, PaddedContentContainer, QueryLarge, QueryMedium, QuerySmall} from "../../view/components/styled"

import SocialMediaIconInstagram from "../../img/SocialMediaIcons1.svg"
import SocialMediaIconFacebook from "../../img/SocialMediaIcons2.svg"
import SocialMediaIconLinkedIn from "../../img/SocialMediaIcons3.svg"

import * as selectors from "../../data/selectors"
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CContact", ...args) // eslint-disable-line

const DEBUG = false && __DEV__

class CContact extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    mediaQueryClass: PropTypes.string.isRequired,
    //
    header: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    job: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    socialMediaUrls: PropTypes.object,
    linkToImpressum: PropTypes.string.isRequired,
    linkToDatenschutz: PropTypes.string.isRequired,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  renderHeader = () => {
    const {header} = this.props
    const d = {debug: DEBUG.toString()}
    return <HeaderText {...d} dangerouslySetInnerHTML={{__html: header}} />
  }
  renderMiddle = () => {
    const {name: fullName, job, email, phone} = this.props
    const d = {debug: DEBUG.toString()}
    return (
      <Fragment>
        <OtherText {...d}>{fullName}</OtherText>
        <OtherText {...d}>{job}</OtherText>
        <OtherText {...d}>&nbsp;</OtherText>
        <OtherText {...d}>
          <StyledA href={`mailto:${email}`}>{email}</StyledA>
        </OtherText>
        <OtherText {...d}>
          <StyledA href={`tel:${phone.split(" ").join("")}`}>{phone}</StyledA>
        </OtherText>
      </Fragment>
    )
  }
  renderRight = () => {
    const {intl, socialMediaUrls, linkToImpressum, linkToDatenschutz} = this.props
    const d = {debug: DEBUG.toString()}
    return (
      <Fragment>
        <OtherText {...d}>Social Media</OtherText>
        {!!socialMediaUrls && (
          <SocialIconsContainer {...d}>
            <SocialIconContainer {...d} href={socialMediaUrls.facebook} target={"_blank"}>
              <SocialIconImage src={SocialMediaIconFacebook} offsetX={-1} />
            </SocialIconContainer>
            <SocialIconContainer {...d} href={socialMediaUrls.instagram} target={"_blank"}>
              <SocialIconImage src={SocialMediaIconInstagram} />
            </SocialIconContainer>
            <SocialIconContainer {...d} href={socialMediaUrls.linkedin} target={"_blank"}>
              <SocialIconImage src={SocialMediaIconLinkedIn} offsetX={0} />
            </SocialIconContainer>
          </SocialIconsContainer>
        )}
        <StyledLink to={linkToImpressum}>{intl.formatMessage({id: `PathImpressum`})}</StyledLink>
        <span> | </span>
        <StyledLink to={linkToDatenschutz}>{intl.formatMessage({id: `PathDatenschutz`})}</StyledLink>
      </Fragment>
    )
  }

  render = () => {
    const {intl, mediaQueryClass} = this.props // eslint-disable-line
    const d = {debug: DEBUG.toString()}
    debby("render()")
    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <MaxScreenWidthContainer {...d}>
            <PaddedContentContainer {...d}>
              {/* 1 row, 3 columns */}

              <QueryLarge>
                <ColumnsContainer {...d}>
                  <ColumnContent type={"header"} {...d}>
                    {this.renderHeader()}
                  </ColumnContent>
                  <ColumnContent type={"middle"} {...d}>
                    {this.renderMiddle()}
                  </ColumnContent>
                  <ColumnContent type={"right"} {...d}>
                    {this.renderRight()}
                  </ColumnContent>
                </ColumnsContainer>
              </QueryLarge>

              <QueryMedium>
                <ColumnContent noPadding type={"header"} {...d}>
                  {this.renderHeader()}
                </ColumnContent>
                <ColumnsContainer {...d}>
                  <ColumnContent type={"middle"} {...d}>
                    {this.renderMiddle()}
                  </ColumnContent>
                  <ColumnContent type={"right"} {...d}>
                    {this.renderRight()}
                  </ColumnContent>
                </ColumnsContainer>
              </QueryMedium>

              <QuerySmall>
                <ColumnContent noPadding type={"header"} {...d}>
                  {this.renderHeader()}
                </ColumnContent>
                <ColumnContent noPadding type={"middle"} {...d}>
                  {this.renderMiddle()}
                </ColumnContent>
                <ColumnContent noPadding type={"right"} {...d}>
                  {this.renderRight()}
                </ColumnContent>
              </QuerySmall>
            </PaddedContentContainer>
          </MaxScreenWidthContainer>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  // border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  ${props => selectors.getDebugOverlayCss(props, "CContact.Container", "rgba(255,255,0,0.35)")}
  padding: 90px 0px;
  color: white;
`

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
  margin: 0px -10px;
  align-items: center;
`

const ColumnContentBase = css`
  padding: 0px 10px;

  ${props =>
    props.noPadding &&
    css`
      padding: 0px;
    `}
`
const ColumnContent = styled.div`
  ${ColumnContentBase}
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;

  ${props =>
    props.type == "header" &&
    css`
      flex: 1;
      margin-bottom: 10px;
    `}

  ${props =>
    props.type == "middle" &&
    css`
      flex: 0.8;
      text-align: center;
      padding: 0px 10px;

      ${props => css`
        ${props.theme.media.md} {
          text-align: left;
          margin-top: 20px;
        }
      `}
      ${props => css`
        ${props.theme.media.smdown} {
          text-align: left;
          padding: 0px;
          margin-top: 20px;
        }
      `}
    `}

  ${props =>
    props.type == "right" &&
    css`
      flex: 1;
      text-align: right;
      padding: 0px 10px;

      ${props => css`
        ${props.theme.media.md} {
          margin-top: 35px; // bottom align
        }
      `}
      ${props => css`
        ${props.theme.media.smdown} {
          padding: 0px;
        }
      `}
    `}

  // font-size: 17px;
  color: white;
`

const HeaderText = styled.div`
  font-size: 24px;
  font-weight: bold;
`

const OtherText = styled.div`
  font-size: 18px;
  font-weight: 500;
`

const StyledA = styled.a`
  color: white;
  text-decoration: none !important;
`

const SocialIconsContainer = styled.div`
  margin-top: 2px;
  width: 100%;
  border: ${props => (props.debug == "true" ? 1 : 0)}px dotted yellow;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 20px;
`

const SocialIconContainer = styled.a`
  width: 30px;
  height: 30px;
  border-radius: 25px;
  background-color: red;
  background-color: ${props => (props.debug == "true" ? "red" : "black")};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  pointer-events: auto;
`

const SocialIconImage = styled.img`
  width: 27px;
  height: 27px;
  object-fit: contain;

  ${props =>
    props.hasOwnProperty("offsetX") &&
    css`
      // opacity: 0.2;
      transform: translate3d(${props.offsetX}px, 0px, 0px);
    `}
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  font-size: 19px;
`

const mapStateToProps = (state, props) => ({
  mediaQueryClass: selectors.getMediaQueryClass(state, props),
  linkToImpressum: selectors.getLinkToImpressum(state), // routes.js
  linkToDatenschutz: selectors.getLinkToDatenschutz(state), // routes.js
})
export default injectIntl(connect(mapStateToProps)(CContact))
