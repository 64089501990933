import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import Fade from "react-reveal/Fade"

import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CReveal", ...args) // eslint-disable-line

const DEBUG = false && __DEV__

class CReveal extends Component {
  static propTypes = {
    children: PropTypes.any,
    bypass: PropTypes.bool,
    delay: PropTypes.number,
    dist: PropTypes.number,
  }
  static defaultProps = {
    bypass: true,
    delay: 0,
    dist: 20,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {bypass, children, delay, dist} = this.props
    const d = {debug: DEBUG.toString()} // eslint-disable-line
    if (bypass) {
      return <Fragment>{children}</Fragment>
    }
    debby("render()")
    return (
      <Fade delay={delay} duration={1000} distance={`${Math.round(dist)}px`} bottom>
        {children}
      </Fade>
    )
  }
}

export default CReveal
