import * as actionsConsts from "../actionsConsts"

export const initialStateDevice = {
  stageDimensions: {
    width: 100,
    height: 100,
  },
  tabVisible: true,
  wpUid: 0,
}

export function device(state = initialStateDevice, action) {
  switch (action.type) {
    case actionsConsts.SET_STAGE_DIMENSIONS: {
      return {...state, stageDimensions: {width: action.width, height: action.height}}
    }
    // case actionsConsts.SET_AD_BLOCK_ACTIVE: {
    //   return {...state, adBlockActive: action.active}
    // }
    // case actionsConsts.SET_SHOWN_IN_FACEBOOK_BROWSER: {
    //   return {...state, shownInFacebookBrowser: action.shownInFacebookBrowser}
    // }
    // case actionsConsts.SET_USER_AGENT_IS_CRAWLER: {
    //   return {...state, userAgentIsCrawler: action.isCrawler, userAgentCrawlerMatches: action.matches}
    // }
    case actionsConsts.SET_TAB_VISIBLE: {
      return {...state, tabVisible: action.visible}
    }
    case actionsConsts.RECEIVE_WP_UID: {
      return {...state, wpUid: action.wpUid}
    }
    default:
      return state
  }
}
