import React, {Component} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"
import {Link} from "react-router-dom" // eslint-disable-line

import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import {MaxScreenWidthContainer, PaddedContentContainer} from "../../view/components/styled"
import CReveal from "../../view/components/CReveal"

import * as selectors from "../../data/selectors"
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CHero", ...args) // eslint-disable-line

// SVGs
import LogoSrc from "../../img/Logo_AndreasLugmayr_ws.svg"
import SocialMediaIconInstagram from "../../img/SocialMediaIcons1.svg" // eslint-disable-line
import SocialMediaIconFacebook from "../../img/SocialMediaIcons2.svg" // eslint-disable-line
import SocialMediaIconLinkedIn from "../../img/SocialMediaIcons3.svg" // eslint-disable-line
// TODO: Missing WhatsApp

const DEBUG = false && __DEV__

class CHero extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    linkToHome: PropTypes.string,
    imageWp: PropTypes.object,
    socialMediaUrls: PropTypes.object,
    pathsInLangs: PropTypes.object,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {intl} = this.props
    const {linkToHome, imageWp, socialMediaUrls, pathsInLangs} = this.props // eslint-disable-line
    const imageWpSources = !imageWp ? null : selectors.getSrcAndSrcSetByWordpressImage(imageWp)
    const d = {debug: DEBUG.toString()}
    return (
      <Container {...d}>
        {!!imageWp && (
          <HeroImageContainer
            //
            {...d}
            // aspectRatio={16 / 9} // uses height of not set
          >
            {/* Background Image */}
            <AbsoluteContainer {...d}>
              <HeroImage {...d} {...imageWpSources} />
            </AbsoluteContainer>

            {/* Logo */}
            <AbsoluteContainer {...d}>
              <StyledLink to={linkToHome}>
                <LogoContainer {...d}>
                  <CReveal delay={500}>
                    <LogoImage src={LogoSrc} />
                  </CReveal>
                </LogoContainer>
              </StyledLink>
            </AbsoluteContainer>

            {/* Language Switch */}
            {!!pathsInLangs && Object.keys(pathsInLangs).length > 1 && (
              <AbsoluteContainer {...d}>
                <MaxScreenWidthContainer {...d}>
                  <PaddedContentContainer {...d} style={{pointerEvents: "auto"}}>
                    <LangSwitchesContainer {...d}>
                      {Object.keys(pathsInLangs).map(lang => (
                        <LangSwitchLink key={`langSwitch_${lang}`} to={pathsInLangs[lang]}>
                          {intl.formatMessage({id: `LangSwitchTo${lang.toUpperCase()}`})}
                        </LangSwitchLink>
                      ))}
                    </LangSwitchesContainer>
                  </PaddedContentContainer>
                </MaxScreenWidthContainer>
              </AbsoluteContainer>
            )}

            {/* Social Icons */}
            <AbsoluteContainer {...d}>
              <MaxScreenWidthContainer {...d}>
                <PaddedContentContainer {...d}>
                  <SocialIconsContainer {...d}>
                    <SocialIconContainer {...d} href={socialMediaUrls.facebook} target={"_blank"}>
                      <SocialIconImage src={SocialMediaIconFacebook} offsetX={0} />
                    </SocialIconContainer>
                    <SocialIconContainer {...d} href={socialMediaUrls.instagram} target={"_blank"}>
                      <SocialIconImage src={SocialMediaIconInstagram} />
                    </SocialIconContainer>
                    <SocialIconContainer {...d} href={socialMediaUrls.linkedin} target={"_blank"}>
                      <SocialIconImage src={SocialMediaIconLinkedIn} offsetX={0} />
                    </SocialIconContainer>
                  </SocialIconsContainer>
                </PaddedContentContainer>
              </MaxScreenWidthContainer>
            </AbsoluteContainer>
          </HeroImageContainer>
        )}

        {false && <pre style={{fontSize: 10}}>{JSON.stringify(imageWpSources, null, 2)}</pre>}
      </Container>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  ${props => selectors.getDebugOverlayCss(props, "CHero.Container", "rgba(255,255,0,0.35)")}
`

const HeroImageContainer = styled.div`
  position: relative;
  width: 100%;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;

  ${props =>
    props.hasOwnProperty("aspectRatio") &&
    css`
      padding-top: ${(1 / props.aspectRatio) * 100}%;
    `}
  ${props =>
    !props.hasOwnProperty("aspectRatio") &&
    css`
      height: 50vh;
      min-height: 400px;
      max-height: 500px;
    `}
`

const AbsoluteContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: white;
  pointer-events: none;
`

const LogoContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border: ${props => (props.debug == "true" ? 3 : 0)}px dotted blue;
  z-index: 1000;
  pointer-events: auto;

  top: 20%; // a.k.a. padding-top
  transition: top 1s;
  ${props => css`
    ${props.theme.media.mddown} {
      top: 0;
    }
  `}
`

const LangSwitchesContainer = styled.div`
  border: ${props => (props.debug == "true" ? 3 : 0)}px dotted blue;
  margin-top: 50px;
`
const LangSwitchLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin-right: 15px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.5);
`

const StyledLink = styled(Link)`
  display: inline-block
  margin: 0 auto;
  text-align: center;
`
const LogoImage = styled.img`
  display: inline-block;
  width: 300px;
  height: auto;
  max-width: 80vw;
  margin: 0 auto;
  // border: 1px solid red;
`

const HeroImage = styled.img`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
`

const SocialIconsContainer = styled.div`
  width: 100%;
  border: ${props => (props.debug == "true" ? 1 : 0)}px dotted yellow;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  margin-top: 50px;

  ${props => css`
    ${props.theme.media.mddown} {
      display: none;
    }
  `}
`

const SocialIconContainer = styled.a`
  width: 35px;
  height: 35px;
  border-radius: 25px;
  background-color: red;
  background-color: ${props => (props.debug == "true" ? "red" : "rgba(0,0,0,0.8)")};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  pointer-events: auto;
  opacity: 0.5;

  transition: opacity 0.5s ease-out;
  :hover {
    opacity: 1;
  }
`

const SocialIconImage = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;

  ${props =>
    props.hasOwnProperty("offsetX") &&
    css`
      // opacity: 0.2;
      transform: translate3d(${props.offsetX}px, 0px, 0px);
    `}
`

// eslint-disable-next-line
const mapStateToProps = (state, props) => ({
  linkToHome: selectors.getLinkToHome(state),
  // locationPathname: selectors.getLocationPathname(state, props),
  // homePathLocalized: selectors.getHomePathLocalized(state, props), // can be undefined
})
export default injectIntl(connect(mapStateToProps)(CHero))
