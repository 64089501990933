import React, {Component} from "react"
import PropTypes from "prop-types"
import {connect} from "react-redux"
import {createSelector} from "reselect"
import {injectIntl} from "react-intl"
import isEqual from "lodash/isEqual"
import {Link} from "react-router-dom"
import PageVisibility from "react-page-visibility"
import DocumentTitle from "react-document-title"
import {Route, Switch} from "react-router-dom"
import {ThemeProvider} from "styled-components"
import {CookiesProvider} from "react-cookie"
import styled, {css} from "styled-components" // eslint-disable-line

import * as actions from "../data/actions"
import * as selectors from "../data/selectors"
import HelperPlatform from "../data/utils/HelperPlatform"

import GlobalStyle from "../styles/GlobalStyle"
import {basicTheme} from "../styles/Theme"

import {getScreenComponentForPageTemplate} from "./Screens"
import SNotFound from "../view/screens/SNotFound"

// import CFooter from "../view/components/CFooter"
// import CCookiePopup from "../view/components/CCookiePopup"

import CONFIG from "../config"

import {debbify, getObjectDeep as god} from "../data/selectors/helpers"
const debby = (...args) => debbify("AppWithState", ...args)

const DEBUG = false && __DEV__
const DEBUG_MEDIA_QUERY = false && __DEV__

class AppWithState extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    intl: PropTypes.object.isRequired,
    stageDimensions: PropTypes.object,
    mediaQueryClass: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    intlLocale: PropTypes.string,
    intlLocaleLocation: PropTypes.string,
    tabVisible: PropTypes.bool,
    documentTitle: PropTypes.string,
    locationPathname: PropTypes.string.isRequired,
    analyticsEnabled: PropTypes.bool,
    sitemapData: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.state = {
      drawPlaceholdersBottom: true,
    }
  }

  componentDidMount = async () => {
    window.addEventListener("resize", this.onWindowResize) // set store's stage dimensions
    this.onWindowResize()

    this.handleProps(this.props, true)
    // methodNotExistingForSentryConfirmation() // eslint-disable-line

    this.props.dispatch(actions.requestSitemap())
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  UNSAFE_componentWillReceiveProps = nextProps => this.handleProps(nextProps, false)

  handleProps = (nextProps, initial) => {
    if (initial || this.props.locationPathname != nextProps.locationPathname) {
      // debugger
      window.scrollTo(0, 0)
    }

    if (initial || nextProps.intlLocale != nextProps.intlLocaleLocation) {
      // console.warn(`AppWithState.handleProps(): setIntlLocale here should be avoided by injecting postData properly in PHP.`, {intlLocale: nextProps.intlLocale, intlLocaleLocation: nextProps.intlLocaleLocation})
      if (!!nextProps.pageTemplate) {
        this.props.dispatch(actions.setIntlLocale(nextProps.intlLocaleLocation))
      }
    }

    // PathData, Page View, Document Lang
    if (initial || this.props.location.pathname != nextProps.location.pathname) {
      this.props.dispatch(actions.requestPostData(nextProps.location.pathname))
      // this.props.dispatch(actions.sendAnalyticsPageView(nextProps.location.pathname))
      HelperPlatform.setDocumentLang(nextProps.intlLocale) // for word-wrap
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.onWindowResize)
    clearTimeout(this.timeoutIdDrawPlaceholdersBottom)
    clearTimeout(this.setStageDimensionsTimeoutId)
  }

  onWindowResize = () => {
    clearTimeout(this.setStageDimensionsTimeoutId)
    this.setStageDimensionsTimeoutId = setTimeout(() => {
      this.props.dispatch(actions.setStageDimensions(window.innerWidth, window.innerHeight))
    }, 200)
  }

  handleVisibilityChange = isVisible => {
    const {dispatch, tabVisible} = this.props
    if (tabVisible == isVisible) {
      return
    }
    debby(`handleVisibilityChange()`, {isVisible})
    // this.props.dispatch(actions.sendAnalyticsEvent(`tab_visibility_set_to_${JSON.stringify(isVisible)}`))
    dispatch(actions.setTabVisible(isVisible)) // ADevice
  }

  renderRouterSwitch = () => {
    const {location, sitemapData} = this.props
    if (!sitemapData) {
      return <div style={{height: "1000vh"}} />
    }
    debby("renderRouterSwitch()", {location})
    return (
      <Switch location={location}>
        {sitemapData
          .filter(entry => entry.hasOwnProperty("pageTemplate"))
          .map(entry => {
            if (!!entry && !!entry.langs) {
              const {pageTemplate, langs} = entry
              const component = getScreenComponentForPageTemplate(pageTemplate) //  Wordpress template filename
              return Object.keys(langs).map(lang => (
                <Route
                  //
                  key={`route_${lang}_${pageTemplate}`}
                  exact
                  path={langs[lang].path}
                  component={component}
                />
              ))
            }
          })}
        <Route component={SNotFound} />
      </Switch>
    )
  }

  render = () => {
    const {intl, documentTitle, analyticsEnabled} = this.props
    const {stageDimensions, browserLang, postData, tabVisible, mediaQueryClass, locationPathname, intlLocale, intlLocaleLocation} = this.props // eslint-disable-line
    debby("render()", {postData, documentTitle, locationPathname})
    const intlWelcome = intl.formatMessage({id: "Welcome"})
    return (
      <PageVisibility onChange={this.handleVisibilityChange}>
        <CookiesProvider>
          <ThemeProvider theme={basicTheme}>
            <GlobalStyle />
            {!!documentTitle && <DocumentTitle title={documentTitle} />}
            {this.renderRouterSwitch()}
            {DEBUG && (
              <div style={{pointerEvents: "none", position: "fixed", fontSize: 12, left: 0, bottom: 30, backgroundColor: "rgba(255,255,0,0.5)", padding: 10, zIndex: 10000}}>
                <div style={{fontWeight: "bold"}}>AppWithState.js</div>
                <div>location: {JSON.stringify(this.props.location)}</div>
                <div>browserLang == {JSON.stringify(browserLang)}</div>
                <div>
                  intlLocale == {JSON.stringify(intlLocale)}: {intlWelcome}
                </div>
                <div>intlLocaleLocation == {JSON.stringify(intlLocaleLocation)}</div>
                <Link to={"/"}>home</Link>| <Link to={"/de"}>home (de)</Link>| <Link to={"/yo"}>yo</Link>| <Link to={"/events/20"}>event 20</Link>| <Link to={"/bullshit"}>bullshit 404</Link>
              </div>
            )}
            {/* Top Left: Media Query */}
            {DEBUG_MEDIA_QUERY && (
              <div style={{position: "fixed", backgroundColor: "rgba(0,255,0,0.5)", left: 0, bottom: 0, fontSize: 12, padding: 5, zIndex: 10001}}>
                {mediaQueryClass.toUpperCase()} {stageDimensions.width}px @ {locationPathname}
              </div>
            )}
            {/* Bottom Right: Analytics Status */}
            {DEBUG_MEDIA_QUERY && <div style={{pointerEvents: "none", position: "fixed", right: 0, bottom: 0, backgroundColor: analyticsEnabled ? "rgba(255,255,0,0.5)" : "rgba(0,255,0,0.5)", fontSize: 12, padding: 5, zIndex: 10000}}>ANALYTICS {analyticsEnabled ? "ENABLED" : "DISABLED"}</div>}
          </ThemeProvider>
        </CookiesProvider>
      </PageVisibility>
    )
  }
}

const getDocumentTitle = createSelector([selectors.getPostData], postData => {
  return god(postData, "seoData.title_raw", CONFIG.app.documentTitle)
})

const mapStateToProps = (state, props) => ({
  intlLocale: selectors.getIntlLocale(state, props),
  browserLang: selectors.getDeviceLocale(),

  analyticsEnabled: state.firebase.analyticsEnabled,
  tabVisible: state.device.tabVisible,
  postData: selectors.getPostData(state, props),
  documentTitle: getDocumentTitle(state, props),
  locationPathname: selectors.getLocationPathname(state, props),
  sitemapData: state.api.sitemap,
  pageTemplate: selectors.getPageTemplate(state, props),
  intlLocaleLocation: selectors.getIntlLocaleLocation(state, props),

  // DEBUG
  stageDimensions: DEBUG || DEBUG_MEDIA_QUERY ? state.device.stageDimensions : {width: 1, height: 1},
  mediaQueryClass: DEBUG || DEBUG_MEDIA_QUERY ? selectors.getMediaQueryClass(state, props) : "-",
})
export default injectIntl(connect(mapStateToProps)(AppWithState))
