import React from "react"
import ReactDOM from "react-dom"
import(/* webpackPreload: true */ "./styles/index.scss")

import reportWebVitals from "./reportWebVitals" // eslint-disable-line

import App from "./App"
import BugReportManager from "./data/utils/BugReportManager"
import CONFIG from "./config"
import packageJson from "../package.json"

if (!__DEV__) {
  console.log = () => {}
  console.warn = () => {}
}
console.log("only visible on __DEV__")

BugReportManager.init({appName: "andreaslugmayr.at", appVersion: packageJson.version, persistKey: CONFIG.redux.storePersistKey})

ReactDOM.render(<App />, document.getElementById("root"))

// reportWebVitals()
