import React, {Component, Fragment} from "react" // eslint-disable-line
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"

import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line

import {MaxScreenWidthContainer, PaddedContentContainer, QueryLarge} from "../../view/components/styled"
import CReveal from "../../view/components/CReveal"

import QuotationMarks from "../../img/quotationmarks.svg"

import * as selectors from "../../data/selectors"
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CHomeMain", ...args)

const DEBUG = false && __DEV__
const REVEAL_DELAY = 50

class CHomeMain extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    mediaQueryClass: PropTypes.string,
    // Content
    textHeader: PropTypes.string,
    textLeft: PropTypes.string,
    textRight: PropTypes.string,
    citationSquares: PropTypes.array,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  componentDidMount = () => {
    debby("componentDidMount()")
  }

  renderCitationSquare = index => {
    const {citationSquares} = this.props
    debby("renderCitationSquare()", {index})
    if (!citationSquares || index > citationSquares.length - 1) return null

    const d = {debug: DEBUG.toString()}
    const square = citationSquares[index]

    switch (square.type) {
      case "text": {
        const {text, showQuotationMark} = square
        return (
          <CitationSquareContainer blackBg {...d}>
            <CitationSquareContent {...d}>
              {!!showQuotationMark && (
                <QuotationMarkContainer {...d}>
                  <QuotationMarkImage {...d} src={QuotationMarks} />
                </QuotationMarkContainer>
              )}
              <QuotationText {...d}>{text}</QuotationText>
            </CitationSquareContent>
          </CitationSquareContainer>
        )
      }
      case "image": {
        const {imageUrl} = square
        return (
          <CitationSquareContainer {...d}>
            <CitationSquareContent {...d}>
              <CitationImage src={imageUrl} />
            </CitationSquareContent>
          </CitationSquareContainer>
        )
      }
    }

    return null
  }

  render = () => {
    // const {intl} = this.props
    const {mediaQueryClass, textHeader, textLeft, textRight, citationSquares} = this.props
    // const imageWpSources = !imageWp ? null : selectors.getSrcAndSrcSetByWordpressImage(imageWp)
    const d = {debug: DEBUG.toString()}
    debby("render()", {mediaQueryClass, textHeader, citationSquares})
    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <MaxScreenWidthContainer {...d}>
            <PaddedContentContainer {...d}>
              {/* XL & LG: 3 Columns -------------------------------------------------------------- */}
              <QueryLarge>
                <ContainerXl {...d}>
                  <ColumnXlLg type={"text"} {...d}>
                    <ColumnContentXl {...d}>
                      <CReveal delay={1 * REVEAL_DELAY}>
                        <MainTextHeader {...d} dangerouslySetInnerHTML={{__html: textHeader}} />
                      </CReveal>
                      <CReveal delay={2 * REVEAL_DELAY}>
                        <MainTextContent {...d} dangerouslySetInnerHTML={{__html: textLeft}} />
                      </CReveal>
                      <CReveal delay={3 * REVEAL_DELAY}>
                        <MainTextContent {...d} dangerouslySetInnerHTML={{__html: textRight}} />
                      </CReveal>
                    </ColumnContentXl>
                  </ColumnXlLg>
                  <ColumnXlLg type={"squares"} {...d}>
                    <ColumnContentXl {...d}>
                      <CReveal delay={4 * REVEAL_DELAY}>{this.renderCitationSquare(1)}</CReveal>
                      <CReveal delay={4.5 * REVEAL_DELAY}>{this.renderCitationSquare(3)}</CReveal>
                    </ColumnContentXl>
                  </ColumnXlLg>
                  <ColumnXlLg type={"squares"} {...d}>
                    <ColumnContentXl {...d}>
                      <CReveal delay={5 * REVEAL_DELAY}>{this.renderCitationSquare(0)}</CReveal>
                      <CReveal delay={5.5 * REVEAL_DELAY}>{this.renderCitationSquare(2)}</CReveal>
                    </ColumnContentXl>
                  </ColumnXlLg>
                </ContainerXl>
              </QueryLarge>

              {/* MD: 2 Columns ------------------------------------------------------------------- */}
              {["md"].indexOf(mediaQueryClass) != -1 && (
                <Fragment>
                  {/* Text */}
                  <ContainerMd {...d}>
                    <ColumnMd {...d}>
                      <CReveal delay={1 * REVEAL_DELAY}>
                        <MainTextHeader {...d} dangerouslySetInnerHTML={{__html: textHeader}} />
                      </CReveal>
                      <CReveal delay={2 * REVEAL_DELAY}>
                        <MainTextContent {...d} dangerouslySetInnerHTML={{__html: textLeft}} />
                      </CReveal>
                    </ColumnMd>

                    <ColumnMd {...d}>
                      <CReveal delay={3 * REVEAL_DELAY}>
                        <MainTextContent {...d} dangerouslySetInnerHTML={{__html: textRight}} />
                      </CReveal>
                    </ColumnMd>
                  </ContainerMd>

                  {/* Image Squares */}
                  <ContainerMd {...d}>
                    <ColumnMd {...d}>
                      <ColumnContentMd {...d}>
                        <CReveal delay={4 * REVEAL_DELAY}>{this.renderCitationSquare(1)}</CReveal>
                        <CReveal delay={4.5 * REVEAL_DELAY}>{this.renderCitationSquare(3)}</CReveal>
                      </ColumnContentMd>
                    </ColumnMd>
                    <ColumnMd {...d}>
                      <ColumnContentMd {...d}>
                        <CReveal delay={4 * REVEAL_DELAY}>{this.renderCitationSquare(0)}</CReveal>
                        <CReveal delay={4.5 * REVEAL_DELAY}>{this.renderCitationSquare(2)}</CReveal>
                      </ColumnContentMd>
                    </ColumnMd>
                  </ContainerMd>
                </Fragment>
              )}

              {/* SM, XS: 1 Column ---------------------------------------------------------------- */}
              {["sm", "xs"].indexOf(mediaQueryClass) != -1 && (
                <Fragment>
                  <ContainerSmXs {...d}>
                    <ColumnSmXs {...d}>
                      <MainTextHeader {...d} dangerouslySetInnerHTML={{__html: textHeader}} />
                      <MainTextContent {...d} dangerouslySetInnerHTML={{__html: textLeft}} />
                      <MainTextContent {...d} dangerouslySetInnerHTML={{__html: textRight}} />
                    </ColumnSmXs>
                    <ColumnSmXs {...d}>
                      <ColumnContentSmXs {...d}>{this.renderCitationSquare(1)}</ColumnContentSmXs>
                      <ColumnContentSmXs {...d}>{this.renderCitationSquare(3)}</ColumnContentSmXs>
                      <ColumnContentSmXs {...d}>{this.renderCitationSquare(2)}</ColumnContentSmXs>
                      <ColumnContentSmXs {...d}>{this.renderCitationSquare(0)}</ColumnContentSmXs>
                    </ColumnSmXs>
                  </ContainerSmXs>
                </Fragment>
              )}
            </PaddedContentContainer>
          </MaxScreenWidthContainer>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  ${props => selectors.getDebugOverlayCss(props, "CHomeMain.Container", "rgba(255,255,0,0.35)")}
  background-color: white;
  color: black;
  padding: 90px 0px;
`

const MainTextHeader = styled.div`
  font-size: 25px;
  font-weight: bold;
  color: black;
  margin-bottom: 20px;
`

const MainTextContent = styled.div`
  color: black;
  font-weight: 500;
  line-height: 1.4;
  hyphens: auto;
  hyphenate-limit-chars: auto 5;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;

  font-size: 17px;
  padding-right: 40px;
  ${props => css`
    ${props.theme.media.lg} {
      font-size: 16px;
      padding-right: 15px;
    }
  `}
  ${props => css`
    ${props.theme.media.md} {
      font-size: 16px;
      padding-right: 0px;
    }
  `}

  p {
    padding: 0px;
    margin: 0px 0px 15px 0px;
  }
`

const ContainerXl = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0px -10px;
`

const ContainerMd = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0px -10px;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid pink;
`

const ContainerSmXs = styled.div`
  position: relative;
  margin: 0px -10px;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid brown;
`

const ColumnXlLg = styled.div`
  position: relative;
  margin: 0px 10px;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;

  flex: 1;
  ${props =>
    props.type == "text" &&
    false &&
    css`
      flex: 1.2;
    `}
`

const ColumnMd = styled.div`
  position: relative;
  margin: 20px 10px;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;

  flex: 1;
`

const ColumnSmXs = styled.div`
  position: relative;
  flex: 1;
  margin: 0px 10px;
  padding-bottom: 30px;
`

const ColumnContentXl = styled.div`
  padding: 0px -10px;
  position: relative;
`

const ColumnContentMd = styled.div`
  padding: 0px -10px;
  position: relative;
`

const ColumnContentSmXs = styled.div`
  padding: 0px -10px;
  position: relative;
`

const CitationSquareContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
  margin-bottom: 20px;

  ${props =>
    props.blackBg &&
    css`
      background-color: black;
    `}
  color: white;
`
const CitationSquareContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const QuotationText = styled.div`
  padding: 7%;
  line-height: 1.5;
  font-weight: 500;

  font-size: 16px;
  ${props => css`
    ${props.theme.media.lg} {
      font-size: 14px;
    }
  `}
  ${props => css`
    ${props.theme.media.sm} {
      font-size: 20px;
    }
  `}
  ${props => css`
    ${props.theme.media.xs} {
      font-size: 16px;
      line-height: 1.4;
    }
  `}
`

const QuotationMarkContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  // background-color: red;
  color: white;
  text-align: center;
`

const QuotationMarkImage = styled.img`
  display: inline-block;
  width: 30%;
`

const CitationImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

// eslint-disable-next-line
const mapStateToProps = (state, props) => ({
  mediaQueryClass: selectors.getMediaQueryClass(state, props),
})
export default injectIntl(connect(mapStateToProps)(CHomeMain))
