import React, {Component, Fragment} from "react"
import PropTypes from "prop-types"
import isEqual from "lodash/isEqual"
import {connect} from "react-redux"
import {injectIntl} from "react-intl"

import styled, {ThemeProvider, css} from "styled-components" // eslint-disable-line
// import CONFIG from "../../config"

import {MaxScreenWidthContainer, PaddedContentContainer} from "../../view/components/styled"

import Star from "../../img/star-filled.svg"

import * as selectors from "../../data/selectors"
import {debbify} from "../../data/selectors/helpers"
const debby = (...args) => debbify("CHomeClients", ...args) // eslint-disable-line

const DEBUG = false && __DEV__

class CHomeClients extends Component {
  static propTypes = {
    intl: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    header: PropTypes.string.isRequired,
    entries: PropTypes.array.isRequired,
  }

  shouldComponentUpdate = (nextProps, nextState) => !(isEqual(nextProps, this.props) && isEqual(nextState, this.state))

  render = () => {
    const {intl} = this.props // eslint-disable-line
    const {header, entries} = this.props // eslint-disable-line
    const d = {debug: DEBUG.toString()}

    const renderStars = () => (
      <Fragment>
        <StarImage src={Star} />
        <StarImage src={Star} />
        <StarImage src={Star} />
        <StarImage src={Star} />
        <StarImage src={Star} />
      </Fragment>
    )

    return (
      <ThemeProvider theme={{}}>
        <Container {...d}>
          <MaxScreenWidthContainer {...d}>
            <PaddedContentContainer {...d}>
              <ColumnsContainer {...d}>
                <ColumnHeaderText {...d}>
                  <HeaderText {...d}>{header}</HeaderText>
                </ColumnHeaderText>
                <ColumnStars {...d}>{renderStars()}</ColumnStars>
              </ColumnsContainer>

              {false && (
                <EntriesText {...d}>
                  {entries.map((e, i) => (
                    <span key={`item_${i}`}>{e.name + (i < entries.length - 1 ? " | " : "")}</span>
                  ))}
                </EntriesText>
              )}
              {true && (
                <EntriesText {...d}>
                  {entries.map((e, i) => (
                    <span
                      //
                      key={`item_${i}`}
                      dangerouslySetInnerHTML={{__html: "<nobr>" + (!!e.link ? `<a href="${e.link}" target="_blank">` : ``) + e.name + (!!e.link ? `</a>` : ``) + (i < entries.length - 1 ? "&nbsp;&nbsp;|&nbsp;&nbsp;" : "") + "</nobr>"}}
                    />
                  ))}
                </EntriesText>
              )}

              <RowStars {...d}>{renderStars()}</RowStars>
            </PaddedContentContainer>
          </MaxScreenWidthContainer>
        </Container>
      </ThemeProvider>
    )
  }
}

const Container = styled.div`
  position: relative;
  width: 100%;
  background-color: black;
  // border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  ${props => selectors.getDebugOverlayCss(props, "CHomeClients.Container", "rgba(255,255,0,0.35)")}
  padding: 30px 0px;
  color: white;
`

const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid yellow;
`

const ColumnHeaderText = styled.div`
  flex: 1;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid red;
  // padding-right: 20px;
`

const ColumnStars = styled.div`
  width: auto;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid pink;

  ${props => css`
    ${props.theme.media.mddown} {
      display: none;
    }
  `}
`

const RowStars = styled.div`
  width: auto;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid pink;
  margin-top: 60px;

  display: none;
  ${props => css`
    ${props.theme.media.mddown} {
      display: block;
    }
  `}
`
const StarImage = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-left: 2px;
  transform: translate3d(-5px, 0px, 0px);
`

const HeaderText = styled.div`
  font-size: 24px;
  font-weight: bold;
`

const EntriesText = styled.div`
  margin-top: 10px;
  // width: 100%;
  border: ${props => (props.debug == "true" ? 1 : 0)}px solid blue;
  font-size: 18px;
  line-height: 1.5;
  // text-align: justify;
  //
  span {
    display: inline-block;

    a {
      color: white !important;
      text-decoration: none !important;
    }
  }
`

// eslint-disable-next-line
const mapStateToProps = (state, props) => ({})
export default injectIntl(connect(mapStateToProps)(CHomeClients))
